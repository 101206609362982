export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"it","file":"translations.js"},{"code":"de","file":"translations.js"},{"code":"fr","file":"translations.js"},{"code":"en","file":"translations.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"no prefix","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"it","file":"translations.js"},{"code":"de","file":"translations.js"},{"code":"fr","file":"translations.js"},{"code":"en","file":"translations.js"}],
  localeCodes: ["it","de","fr","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "t",
  3: "r",
  4: "a",
  5: "n",
  6: "s",
  7: "l",
  8: "a",
  9: "t",
  10: "i",
  11: "o",
  12: "n",
  13: "s",
  14: ".",
  15: "j",
  16: "s",
  17: "\"",
  18: ":",
  19: "\"",
  20: ".",
  21: ".",
  22: "/",
  23: "l",
  24: "a",
  25: "n",
  26: "g",
  27: "/",
  28: "t",
  29: "r",
  30: "a",
  31: "n",
  32: "s",
  33: "l",
  34: "a",
  35: "t",
  36: "i",
  37: "o",
  38: "n",
  39: "s",
  40: ".",
  41: "j",
  42: "s",
  43: "\"",
  44: "}",
}

export const localeMessages = {
  'translations.js': () => import('../../lang/translations.js' /* webpackChunkName: "lang-translations.js" */),
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _717de309 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _2d46fb63 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _096ff69c = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _4212d1f5 = () => interopDefault(import('../pages/properties/index.vue' /* webpackChunkName: "pages/properties/index" */))
const _851f35e8 = () => interopDefault(import('../pages/properties-list/index.vue' /* webpackChunkName: "pages/properties-list/index" */))
const _7a971cb9 = () => interopDefault(import('../pages/quotes/index.vue' /* webpackChunkName: "pages/quotes/index" */))
const _5aa8d36e = () => interopDefault(import('../pages/sorry.vue' /* webpackChunkName: "pages/sorry" */))
const _ef825e10 = () => interopDefault(import('../pages/book/confirmed.vue' /* webpackChunkName: "pages/book/confirmed" */))
const _ebedc616 = () => interopDefault(import('../pages/book/credit-card-confirmation.vue' /* webpackChunkName: "pages/book/credit-card-confirmation" */))
const _20fb620c = () => interopDefault(import('../pages/portal/checkin/index.vue' /* webpackChunkName: "pages/portal/checkin/index" */))
const _1e34eb29 = () => interopDefault(import('../pages/portal/contract/index.vue' /* webpackChunkName: "pages/portal/contract/index" */))
const _758216c0 = () => interopDefault(import('../pages/portal/guestbook/index.vue' /* webpackChunkName: "pages/portal/guestbook/index" */))
const _006cc81f = () => interopDefault(import('../pages/portal/payment-method/index.vue' /* webpackChunkName: "pages/portal/payment-method/index" */))
const _c4f5bf64 = () => interopDefault(import('../pages/portal/quotes/index.vue' /* webpackChunkName: "pages/portal/quotes/index" */))
const _0df34804 = () => interopDefault(import('../pages/portal/reservations/index.vue' /* webpackChunkName: "pages/portal/reservations/index" */))
const _f58de4a8 = () => interopDefault(import('../pages/portal/transactions/index.vue' /* webpackChunkName: "pages/portal/transactions/index" */))
const _3de59b48 = () => interopDefault(import('../pages/book/steps/ClientDetails.vue' /* webpackChunkName: "pages/book/steps/ClientDetails" */))
const _5acab53c = () => interopDefault(import('../pages/book/steps/Extras.vue' /* webpackChunkName: "pages/book/steps/Extras" */))
const _bea17ed2 = () => interopDefault(import('../pages/book/steps/Payment.vue' /* webpackChunkName: "pages/book/steps/Payment" */))
const _95343392 = () => interopDefault(import('../pages/book/steps/Summary.vue' /* webpackChunkName: "pages/book/steps/Summary" */))
const _441882d5 = () => interopDefault(import('../pages/portal/contract/signed.vue' /* webpackChunkName: "pages/portal/contract/signed" */))
const _40889a5b = () => interopDefault(import('../pages/portal/quotes/confirmed.vue' /* webpackChunkName: "pages/portal/quotes/confirmed" */))
const _4d85fd9c = () => interopDefault(import('../pages/portal/quotes/credit-card-confirmation.vue' /* webpackChunkName: "pages/portal/quotes/credit-card-confirmation" */))
const _2fc74782 = () => interopDefault(import('../pages/portal/reservations/credit-card-confirmation.vue' /* webpackChunkName: "pages/portal/reservations/credit-card-confirmation" */))
const _e7d87e28 = () => interopDefault(import('../pages/book/steps/mixins/confirmation.js' /* webpackChunkName: "pages/book/steps/mixins/confirmation" */))
const _45ea80c2 = () => interopDefault(import('../pages/book/steps/partials/ExtraCard.vue' /* webpackChunkName: "pages/book/steps/partials/ExtraCard" */))
const _fb019a4a = () => interopDefault(import('../pages/book/steps/paymentMethods/bankTransfer.vue' /* webpackChunkName: "pages/book/steps/paymentMethods/bankTransfer" */))
const _f800df6c = () => interopDefault(import('../pages/book/steps/paymentMethods/cardNowNexi.vue' /* webpackChunkName: "pages/book/steps/paymentMethods/cardNowNexi" */))
const _0fd0bea2 = () => interopDefault(import('../pages/book/steps/paymentMethods/cardNowStripe.vue' /* webpackChunkName: "pages/book/steps/paymentMethods/cardNowStripe" */))
const _fb512472 = () => interopDefault(import('../pages/book/steps/paymentMethods/cash.vue' /* webpackChunkName: "pages/book/steps/paymentMethods/cash" */))
const _6c787e62 = () => interopDefault(import('../pages/book/steps/paymentMethods/creditCardSecom.vue' /* webpackChunkName: "pages/book/steps/paymentMethods/creditCardSecom" */))
const _0c58e958 = () => interopDefault(import('../pages/portal/checkin/mixins/form.js' /* webpackChunkName: "pages/portal/checkin/mixins/form" */))
const _225fa07a = () => interopDefault(import('../pages/portal/quotes/steps/Payment.vue' /* webpackChunkName: "pages/portal/quotes/steps/Payment" */))
const _3716461a = () => interopDefault(import('../pages/portal/quotes/steps/Summary.vue' /* webpackChunkName: "pages/portal/quotes/steps/Summary" */))
const _4970b5ae = () => interopDefault(import('../pages/portal/quotes/steps/mixins/confirmation.js' /* webpackChunkName: "pages/portal/quotes/steps/mixins/confirmation" */))
const _3aebc33e = () => interopDefault(import('../pages/portal/quotes/steps/paymentMethods/bankTransfer.vue' /* webpackChunkName: "pages/portal/quotes/steps/paymentMethods/bankTransfer" */))
const _673eb7ff = () => interopDefault(import('../pages/portal/quotes/steps/paymentMethods/cardNow.vue' /* webpackChunkName: "pages/portal/quotes/steps/paymentMethods/cardNow" */))
const _19a0112a = () => interopDefault(import('../pages/portal/quotes/steps/paymentMethods/cash.vue' /* webpackChunkName: "pages/portal/quotes/steps/paymentMethods/cash" */))
const _0e2eef1f = () => interopDefault(import('../pages/portal/quotes/steps/paymentMethods/creditCardSecom.vue' /* webpackChunkName: "pages/portal/quotes/steps/paymentMethods/creditCardSecom" */))
const _2c2cc526 = () => interopDefault(import('../pages/portal/manage/_company_id.vue' /* webpackChunkName: "pages/portal/manage/_company_id" */))
const _592838de = () => interopDefault(import('../pages/accommodation/_slug.vue' /* webpackChunkName: "pages/accommodation/_slug" */))
const _8e9ce2da = () => interopDefault(import('../pages/book/_id.vue' /* webpackChunkName: "pages/book/_id" */))
const _beb264c6 = () => interopDefault(import('../pages/properties/_id.vue' /* webpackChunkName: "pages/properties/_id" */))
const _2b905a1b = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _717de309,
    name: "404___en"
  }, {
    path: "/de",
    component: _2d46fb63,
    name: "index___de"
  }, {
    path: "/fr",
    component: _2d46fb63,
    name: "index___fr"
  }, {
    path: "/it",
    component: _2d46fb63,
    name: "index___it"
  }, {
    path: "/promotions",
    component: _096ff69c,
    name: "promotions___en"
  }, {
    path: "/properties",
    component: _4212d1f5,
    name: "properties___en"
  }, {
    path: "/properties-list",
    component: _851f35e8,
    name: "properties-list___en"
  }, {
    path: "/quotes",
    component: _7a971cb9,
    name: "quotes___en"
  }, {
    path: "/sorry",
    component: _5aa8d36e,
    name: "sorry___en"
  }, {
    path: "/book/confirmed",
    component: _ef825e10,
    name: "book-confirmed___en"
  }, {
    path: "/book/credit-card-confirmation",
    component: _ebedc616,
    name: "book-credit-card-confirmation___en"
  }, {
    path: "/de/404",
    component: _717de309,
    name: "404___de"
  }, {
    path: "/de/promotions",
    component: _096ff69c,
    name: "promotions___de"
  }, {
    path: "/de/properties",
    component: _4212d1f5,
    name: "properties___de"
  }, {
    path: "/de/properties-list",
    component: _851f35e8,
    name: "properties-list___de"
  }, {
    path: "/de/quotes",
    component: _7a971cb9,
    name: "quotes___de"
  }, {
    path: "/de/sorry",
    component: _5aa8d36e,
    name: "sorry___de"
  }, {
    path: "/fr/404",
    component: _717de309,
    name: "404___fr"
  }, {
    path: "/fr/promotions",
    component: _096ff69c,
    name: "promotions___fr"
  }, {
    path: "/fr/properties",
    component: _4212d1f5,
    name: "properties___fr"
  }, {
    path: "/fr/properties-list",
    component: _851f35e8,
    name: "properties-list___fr"
  }, {
    path: "/fr/quotes",
    component: _7a971cb9,
    name: "quotes___fr"
  }, {
    path: "/fr/sorry",
    component: _5aa8d36e,
    name: "sorry___fr"
  }, {
    path: "/it/404",
    component: _717de309,
    name: "404___it"
  }, {
    path: "/it/promotions",
    component: _096ff69c,
    name: "promotions___it"
  }, {
    path: "/it/properties",
    component: _4212d1f5,
    name: "properties___it"
  }, {
    path: "/it/properties-list",
    component: _851f35e8,
    name: "properties-list___it"
  }, {
    path: "/it/quotes",
    component: _7a971cb9,
    name: "quotes___it"
  }, {
    path: "/it/sorry",
    component: _5aa8d36e,
    name: "sorry___it"
  }, {
    path: "/portal/checkin",
    component: _20fb620c,
    name: "portal-checkin___en"
  }, {
    path: "/portal/contract",
    component: _1e34eb29,
    name: "portal-contract___en"
  }, {
    path: "/portal/guestbook",
    component: _758216c0,
    name: "portal-guestbook___en"
  }, {
    path: "/portal/payment-method",
    component: _006cc81f,
    name: "portal-payment-method___en"
  }, {
    path: "/portal/quotes",
    component: _c4f5bf64,
    name: "portal-quotes___en"
  }, {
    path: "/portal/reservations",
    component: _0df34804,
    name: "portal-reservations___en"
  }, {
    path: "/portal/transactions",
    component: _f58de4a8,
    name: "portal-transactions___en"
  }, {
    path: "/book/steps/ClientDetails",
    component: _3de59b48,
    name: "book-steps-ClientDetails___en"
  }, {
    path: "/book/steps/Extras",
    component: _5acab53c,
    name: "book-steps-Extras___en"
  }, {
    path: "/book/steps/Payment",
    component: _bea17ed2,
    name: "book-steps-Payment___en"
  }, {
    path: "/book/steps/Summary",
    component: _95343392,
    name: "book-steps-Summary___en"
  }, {
    path: "/de/book/confirmed",
    component: _ef825e10,
    name: "book-confirmed___de"
  }, {
    path: "/de/book/credit-card-confirmation",
    component: _ebedc616,
    name: "book-credit-card-confirmation___de"
  }, {
    path: "/de/portal/checkin",
    component: _20fb620c,
    name: "portal-checkin___de"
  }, {
    path: "/de/portal/contract",
    component: _1e34eb29,
    name: "portal-contract___de"
  }, {
    path: "/de/portal/guestbook",
    component: _758216c0,
    name: "portal-guestbook___de"
  }, {
    path: "/de/portal/payment-method",
    component: _006cc81f,
    name: "portal-payment-method___de"
  }, {
    path: "/de/portal/quotes",
    component: _c4f5bf64,
    name: "portal-quotes___de"
  }, {
    path: "/de/portal/reservations",
    component: _0df34804,
    name: "portal-reservations___de"
  }, {
    path: "/de/portal/transactions",
    component: _f58de4a8,
    name: "portal-transactions___de"
  }, {
    path: "/fr/book/confirmed",
    component: _ef825e10,
    name: "book-confirmed___fr"
  }, {
    path: "/fr/book/credit-card-confirmation",
    component: _ebedc616,
    name: "book-credit-card-confirmation___fr"
  }, {
    path: "/fr/portal/checkin",
    component: _20fb620c,
    name: "portal-checkin___fr"
  }, {
    path: "/fr/portal/contract",
    component: _1e34eb29,
    name: "portal-contract___fr"
  }, {
    path: "/fr/portal/guestbook",
    component: _758216c0,
    name: "portal-guestbook___fr"
  }, {
    path: "/fr/portal/payment-method",
    component: _006cc81f,
    name: "portal-payment-method___fr"
  }, {
    path: "/fr/portal/quotes",
    component: _c4f5bf64,
    name: "portal-quotes___fr"
  }, {
    path: "/fr/portal/reservations",
    component: _0df34804,
    name: "portal-reservations___fr"
  }, {
    path: "/fr/portal/transactions",
    component: _f58de4a8,
    name: "portal-transactions___fr"
  }, {
    path: "/it/book/confirmed",
    component: _ef825e10,
    name: "book-confirmed___it"
  }, {
    path: "/it/book/credit-card-confirmation",
    component: _ebedc616,
    name: "book-credit-card-confirmation___it"
  }, {
    path: "/it/portal/checkin",
    component: _20fb620c,
    name: "portal-checkin___it"
  }, {
    path: "/it/portal/contract",
    component: _1e34eb29,
    name: "portal-contract___it"
  }, {
    path: "/it/portal/guestbook",
    component: _758216c0,
    name: "portal-guestbook___it"
  }, {
    path: "/it/portal/payment-method",
    component: _006cc81f,
    name: "portal-payment-method___it"
  }, {
    path: "/it/portal/quotes",
    component: _c4f5bf64,
    name: "portal-quotes___it"
  }, {
    path: "/it/portal/reservations",
    component: _0df34804,
    name: "portal-reservations___it"
  }, {
    path: "/it/portal/transactions",
    component: _f58de4a8,
    name: "portal-transactions___it"
  }, {
    path: "/portal/contract/signed",
    component: _441882d5,
    name: "portal-contract-signed___en"
  }, {
    path: "/portal/quotes/confirmed",
    component: _40889a5b,
    name: "portal-quotes-confirmed___en"
  }, {
    path: "/portal/quotes/credit-card-confirmation",
    component: _4d85fd9c,
    name: "portal-quotes-credit-card-confirmation___en"
  }, {
    path: "/portal/reservations/credit-card-confirmation",
    component: _2fc74782,
    name: "portal-reservations-credit-card-confirmation___en"
  }, {
    path: "/book/steps/mixins/confirmation",
    component: _e7d87e28,
    name: "book-steps-mixins-confirmation___en"
  }, {
    path: "/book/steps/partials/ExtraCard",
    component: _45ea80c2,
    name: "book-steps-partials-ExtraCard___en"
  }, {
    path: "/book/steps/paymentMethods/bankTransfer",
    component: _fb019a4a,
    name: "book-steps-paymentMethods-bankTransfer___en"
  }, {
    path: "/book/steps/paymentMethods/cardNowNexi",
    component: _f800df6c,
    name: "book-steps-paymentMethods-cardNowNexi___en"
  }, {
    path: "/book/steps/paymentMethods/cardNowStripe",
    component: _0fd0bea2,
    name: "book-steps-paymentMethods-cardNowStripe___en"
  }, {
    path: "/book/steps/paymentMethods/cash",
    component: _fb512472,
    name: "book-steps-paymentMethods-cash___en"
  }, {
    path: "/book/steps/paymentMethods/creditCardSecom",
    component: _6c787e62,
    name: "book-steps-paymentMethods-creditCardSecom___en"
  }, {
    path: "/de/book/steps/ClientDetails",
    component: _3de59b48,
    name: "book-steps-ClientDetails___de"
  }, {
    path: "/de/book/steps/Extras",
    component: _5acab53c,
    name: "book-steps-Extras___de"
  }, {
    path: "/de/book/steps/Payment",
    component: _bea17ed2,
    name: "book-steps-Payment___de"
  }, {
    path: "/de/book/steps/Summary",
    component: _95343392,
    name: "book-steps-Summary___de"
  }, {
    path: "/de/portal/contract/signed",
    component: _441882d5,
    name: "portal-contract-signed___de"
  }, {
    path: "/de/portal/quotes/confirmed",
    component: _40889a5b,
    name: "portal-quotes-confirmed___de"
  }, {
    path: "/de/portal/quotes/credit-card-confirmation",
    component: _4d85fd9c,
    name: "portal-quotes-credit-card-confirmation___de"
  }, {
    path: "/de/portal/reservations/credit-card-confirmation",
    component: _2fc74782,
    name: "portal-reservations-credit-card-confirmation___de"
  }, {
    path: "/fr/book/steps/ClientDetails",
    component: _3de59b48,
    name: "book-steps-ClientDetails___fr"
  }, {
    path: "/fr/book/steps/Extras",
    component: _5acab53c,
    name: "book-steps-Extras___fr"
  }, {
    path: "/fr/book/steps/Payment",
    component: _bea17ed2,
    name: "book-steps-Payment___fr"
  }, {
    path: "/fr/book/steps/Summary",
    component: _95343392,
    name: "book-steps-Summary___fr"
  }, {
    path: "/fr/portal/contract/signed",
    component: _441882d5,
    name: "portal-contract-signed___fr"
  }, {
    path: "/fr/portal/quotes/confirmed",
    component: _40889a5b,
    name: "portal-quotes-confirmed___fr"
  }, {
    path: "/fr/portal/quotes/credit-card-confirmation",
    component: _4d85fd9c,
    name: "portal-quotes-credit-card-confirmation___fr"
  }, {
    path: "/fr/portal/reservations/credit-card-confirmation",
    component: _2fc74782,
    name: "portal-reservations-credit-card-confirmation___fr"
  }, {
    path: "/it/book/steps/ClientDetails",
    component: _3de59b48,
    name: "book-steps-ClientDetails___it"
  }, {
    path: "/it/book/steps/Extras",
    component: _5acab53c,
    name: "book-steps-Extras___it"
  }, {
    path: "/it/book/steps/Payment",
    component: _bea17ed2,
    name: "book-steps-Payment___it"
  }, {
    path: "/it/book/steps/Summary",
    component: _95343392,
    name: "book-steps-Summary___it"
  }, {
    path: "/it/portal/contract/signed",
    component: _441882d5,
    name: "portal-contract-signed___it"
  }, {
    path: "/it/portal/quotes/confirmed",
    component: _40889a5b,
    name: "portal-quotes-confirmed___it"
  }, {
    path: "/it/portal/quotes/credit-card-confirmation",
    component: _4d85fd9c,
    name: "portal-quotes-credit-card-confirmation___it"
  }, {
    path: "/it/portal/reservations/credit-card-confirmation",
    component: _2fc74782,
    name: "portal-reservations-credit-card-confirmation___it"
  }, {
    path: "/portal/checkin/mixins/form",
    component: _0c58e958,
    name: "portal-checkin-mixins-form___en"
  }, {
    path: "/portal/quotes/steps/Payment",
    component: _225fa07a,
    name: "portal-quotes-steps-Payment___en"
  }, {
    path: "/portal/quotes/steps/Summary",
    component: _3716461a,
    name: "portal-quotes-steps-Summary___en"
  }, {
    path: "/de/book/steps/mixins/confirmation",
    component: _e7d87e28,
    name: "book-steps-mixins-confirmation___de"
  }, {
    path: "/de/book/steps/partials/ExtraCard",
    component: _45ea80c2,
    name: "book-steps-partials-ExtraCard___de"
  }, {
    path: "/de/book/steps/paymentMethods/bankTransfer",
    component: _fb019a4a,
    name: "book-steps-paymentMethods-bankTransfer___de"
  }, {
    path: "/de/book/steps/paymentMethods/cardNowNexi",
    component: _f800df6c,
    name: "book-steps-paymentMethods-cardNowNexi___de"
  }, {
    path: "/de/book/steps/paymentMethods/cardNowStripe",
    component: _0fd0bea2,
    name: "book-steps-paymentMethods-cardNowStripe___de"
  }, {
    path: "/de/book/steps/paymentMethods/cash",
    component: _fb512472,
    name: "book-steps-paymentMethods-cash___de"
  }, {
    path: "/de/book/steps/paymentMethods/creditCardSecom",
    component: _6c787e62,
    name: "book-steps-paymentMethods-creditCardSecom___de"
  }, {
    path: "/de/portal/checkin/mixins/form",
    component: _0c58e958,
    name: "portal-checkin-mixins-form___de"
  }, {
    path: "/de/portal/quotes/steps/Payment",
    component: _225fa07a,
    name: "portal-quotes-steps-Payment___de"
  }, {
    path: "/de/portal/quotes/steps/Summary",
    component: _3716461a,
    name: "portal-quotes-steps-Summary___de"
  }, {
    path: "/fr/book/steps/mixins/confirmation",
    component: _e7d87e28,
    name: "book-steps-mixins-confirmation___fr"
  }, {
    path: "/fr/book/steps/partials/ExtraCard",
    component: _45ea80c2,
    name: "book-steps-partials-ExtraCard___fr"
  }, {
    path: "/fr/book/steps/paymentMethods/bankTransfer",
    component: _fb019a4a,
    name: "book-steps-paymentMethods-bankTransfer___fr"
  }, {
    path: "/fr/book/steps/paymentMethods/cardNowNexi",
    component: _f800df6c,
    name: "book-steps-paymentMethods-cardNowNexi___fr"
  }, {
    path: "/fr/book/steps/paymentMethods/cardNowStripe",
    component: _0fd0bea2,
    name: "book-steps-paymentMethods-cardNowStripe___fr"
  }, {
    path: "/fr/book/steps/paymentMethods/cash",
    component: _fb512472,
    name: "book-steps-paymentMethods-cash___fr"
  }, {
    path: "/fr/book/steps/paymentMethods/creditCardSecom",
    component: _6c787e62,
    name: "book-steps-paymentMethods-creditCardSecom___fr"
  }, {
    path: "/fr/portal/checkin/mixins/form",
    component: _0c58e958,
    name: "portal-checkin-mixins-form___fr"
  }, {
    path: "/fr/portal/quotes/steps/Payment",
    component: _225fa07a,
    name: "portal-quotes-steps-Payment___fr"
  }, {
    path: "/fr/portal/quotes/steps/Summary",
    component: _3716461a,
    name: "portal-quotes-steps-Summary___fr"
  }, {
    path: "/it/book/steps/mixins/confirmation",
    component: _e7d87e28,
    name: "book-steps-mixins-confirmation___it"
  }, {
    path: "/it/book/steps/partials/ExtraCard",
    component: _45ea80c2,
    name: "book-steps-partials-ExtraCard___it"
  }, {
    path: "/it/book/steps/paymentMethods/bankTransfer",
    component: _fb019a4a,
    name: "book-steps-paymentMethods-bankTransfer___it"
  }, {
    path: "/it/book/steps/paymentMethods/cardNowNexi",
    component: _f800df6c,
    name: "book-steps-paymentMethods-cardNowNexi___it"
  }, {
    path: "/it/book/steps/paymentMethods/cardNowStripe",
    component: _0fd0bea2,
    name: "book-steps-paymentMethods-cardNowStripe___it"
  }, {
    path: "/it/book/steps/paymentMethods/cash",
    component: _fb512472,
    name: "book-steps-paymentMethods-cash___it"
  }, {
    path: "/it/book/steps/paymentMethods/creditCardSecom",
    component: _6c787e62,
    name: "book-steps-paymentMethods-creditCardSecom___it"
  }, {
    path: "/it/portal/checkin/mixins/form",
    component: _0c58e958,
    name: "portal-checkin-mixins-form___it"
  }, {
    path: "/it/portal/quotes/steps/Payment",
    component: _225fa07a,
    name: "portal-quotes-steps-Payment___it"
  }, {
    path: "/it/portal/quotes/steps/Summary",
    component: _3716461a,
    name: "portal-quotes-steps-Summary___it"
  }, {
    path: "/portal/quotes/steps/mixins/confirmation",
    component: _4970b5ae,
    name: "portal-quotes-steps-mixins-confirmation___en"
  }, {
    path: "/portal/quotes/steps/paymentMethods/bankTransfer",
    component: _3aebc33e,
    name: "portal-quotes-steps-paymentMethods-bankTransfer___en"
  }, {
    path: "/portal/quotes/steps/paymentMethods/cardNow",
    component: _673eb7ff,
    name: "portal-quotes-steps-paymentMethods-cardNow___en"
  }, {
    path: "/portal/quotes/steps/paymentMethods/cash",
    component: _19a0112a,
    name: "portal-quotes-steps-paymentMethods-cash___en"
  }, {
    path: "/portal/quotes/steps/paymentMethods/creditCardSecom",
    component: _0e2eef1f,
    name: "portal-quotes-steps-paymentMethods-creditCardSecom___en"
  }, {
    path: "/de/portal/quotes/steps/mixins/confirmation",
    component: _4970b5ae,
    name: "portal-quotes-steps-mixins-confirmation___de"
  }, {
    path: "/de/portal/quotes/steps/paymentMethods/bankTransfer",
    component: _3aebc33e,
    name: "portal-quotes-steps-paymentMethods-bankTransfer___de"
  }, {
    path: "/de/portal/quotes/steps/paymentMethods/cardNow",
    component: _673eb7ff,
    name: "portal-quotes-steps-paymentMethods-cardNow___de"
  }, {
    path: "/de/portal/quotes/steps/paymentMethods/cash",
    component: _19a0112a,
    name: "portal-quotes-steps-paymentMethods-cash___de"
  }, {
    path: "/de/portal/quotes/steps/paymentMethods/creditCardSecom",
    component: _0e2eef1f,
    name: "portal-quotes-steps-paymentMethods-creditCardSecom___de"
  }, {
    path: "/fr/portal/quotes/steps/mixins/confirmation",
    component: _4970b5ae,
    name: "portal-quotes-steps-mixins-confirmation___fr"
  }, {
    path: "/fr/portal/quotes/steps/paymentMethods/bankTransfer",
    component: _3aebc33e,
    name: "portal-quotes-steps-paymentMethods-bankTransfer___fr"
  }, {
    path: "/fr/portal/quotes/steps/paymentMethods/cardNow",
    component: _673eb7ff,
    name: "portal-quotes-steps-paymentMethods-cardNow___fr"
  }, {
    path: "/fr/portal/quotes/steps/paymentMethods/cash",
    component: _19a0112a,
    name: "portal-quotes-steps-paymentMethods-cash___fr"
  }, {
    path: "/fr/portal/quotes/steps/paymentMethods/creditCardSecom",
    component: _0e2eef1f,
    name: "portal-quotes-steps-paymentMethods-creditCardSecom___fr"
  }, {
    path: "/it/portal/quotes/steps/mixins/confirmation",
    component: _4970b5ae,
    name: "portal-quotes-steps-mixins-confirmation___it"
  }, {
    path: "/it/portal/quotes/steps/paymentMethods/bankTransfer",
    component: _3aebc33e,
    name: "portal-quotes-steps-paymentMethods-bankTransfer___it"
  }, {
    path: "/it/portal/quotes/steps/paymentMethods/cardNow",
    component: _673eb7ff,
    name: "portal-quotes-steps-paymentMethods-cardNow___it"
  }, {
    path: "/it/portal/quotes/steps/paymentMethods/cash",
    component: _19a0112a,
    name: "portal-quotes-steps-paymentMethods-cash___it"
  }, {
    path: "/it/portal/quotes/steps/paymentMethods/creditCardSecom",
    component: _0e2eef1f,
    name: "portal-quotes-steps-paymentMethods-creditCardSecom___it"
  }, {
    path: "/",
    component: _2d46fb63,
    name: "index___en"
  }, {
    path: "/de/portal/manage/:company_id?",
    component: _2c2cc526,
    name: "portal-manage-company_id___de"
  }, {
    path: "/fr/portal/manage/:company_id?",
    component: _2c2cc526,
    name: "portal-manage-company_id___fr"
  }, {
    path: "/it/portal/manage/:company_id?",
    component: _2c2cc526,
    name: "portal-manage-company_id___it"
  }, {
    path: "/de/accommodation/:slug?",
    component: _592838de,
    name: "accommodation-slug___de"
  }, {
    path: "/de/book/:id?",
    component: _8e9ce2da,
    name: "book-id___de"
  }, {
    path: "/de/properties/:id",
    component: _beb264c6,
    name: "properties-id___de"
  }, {
    path: "/fr/accommodation/:slug?",
    component: _592838de,
    name: "accommodation-slug___fr"
  }, {
    path: "/fr/book/:id?",
    component: _8e9ce2da,
    name: "book-id___fr"
  }, {
    path: "/fr/properties/:id",
    component: _beb264c6,
    name: "properties-id___fr"
  }, {
    path: "/it/accommodation/:slug?",
    component: _592838de,
    name: "accommodation-slug___it"
  }, {
    path: "/it/book/:id?",
    component: _8e9ce2da,
    name: "book-id___it"
  }, {
    path: "/it/properties/:id",
    component: _beb264c6,
    name: "properties-id___it"
  }, {
    path: "/portal/manage/:company_id?",
    component: _2c2cc526,
    name: "portal-manage-company_id___en"
  }, {
    path: "/accommodation/:slug?",
    component: _592838de,
    name: "accommodation-slug___en"
  }, {
    path: "/book/:id?",
    component: _8e9ce2da,
    name: "book-id___en"
  }, {
    path: "/de/:slug",
    component: _2b905a1b,
    name: "slug___de"
  }, {
    path: "/fr/:slug",
    component: _2b905a1b,
    name: "slug___fr"
  }, {
    path: "/it/:slug",
    component: _2b905a1b,
    name: "slug___it"
  }, {
    path: "/properties/:id",
    component: _beb264c6,
    name: "properties-id___en"
  }, {
    path: "/:slug",
    component: _2b905a1b,
    name: "slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
